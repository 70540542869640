const BIG_ROUND_NUMBER_FOR_ACCURACY = 1_000_000_000_000
const BIG_ROUND_NUMBER_FOR_DIVIDE = 1_000_000_000_0

/**
 * Calculate amount*rate
 * @param amount from front input, example 100, 100.22, 10.00
 * @param rate example 1.03, 6.497999999999999
 * @return payoff as Corm ready for front output
 * @example
 calculateFrontPayoff(1000, 0) // 0
 calculateFrontPayoff(100,  6.497999999999999) // 649.8
 *
 */
export function calculateFrontPayoff(amount: number, rate: number): number {
  return (
    Math.floor(
      Math.round(rate * BIG_ROUND_NUMBER_FOR_ACCURACY * amount) /
        BIG_ROUND_NUMBER_FOR_DIVIDE,
    ) / 100
  )
}

/**
 * Calculate amount*rate like Corm
 * @param amount from front backend, example 2_00, 100_22, 10_00
 * @param rate example 1.03, 6.497999999999999
 * @return payoff as Corm to process via amountFormatter
 * @example
 calculateBackPayoff(200, 1.2) // 240
 calculateBackPayoff(1000_00, 6.497999999999999) // 6497.99
 *
 */
export function calculateBackPayoff(amount: number, rate: number): number {
  return Math.floor(
    (Math.round(rate * BIG_ROUND_NUMBER_FOR_ACCURACY) * amount) /
      BIG_ROUND_NUMBER_FOR_ACCURACY,
  )
}

/**
 * Returns rounded rate like Corm
 * @param rate
 * @return rate
 * @example
 calculateRate(0) // 1
 calculateRate(6.49) // 6.49
 calculateRate(6.497999999999999) //  6.498
 *
 */
export function calculateRate(rate: number): number {
  return (
    Math.round(rate * BIG_ROUND_NUMBER_FOR_ACCURACY) /
    BIG_ROUND_NUMBER_FOR_ACCURACY
  )
}

/**
 * Returns rate with 2 fractional digits by removing rest fractional digits
 * @param rate
 * @return rate
 * @example
 truncRate(0) // 0
 truncRate(2) // 2.00
 truncRate(6.49) // 6.49
 truncRate(6.497999999999999) //  6.49
 *
 */
export function truncRate(rate: number): string {
  return (Math.trunc(rate * 100) / 100).toFixed(2)
}

/**
 * Calculate front amount win from back limit
 * @param payoff limit from back, example 35523
 * @param rate example 1.03, 6.497999999999999
 * @return max user amount to make bet
 */
export function calculateFrontAmount(payoff: number, rate: number): number {
  return (
    Math.floor(
      Math.round((payoff / rate) * BIG_ROUND_NUMBER_FOR_ACCURACY) /
        BIG_ROUND_NUMBER_FOR_DIVIDE,
    ) / 100
  )
}
